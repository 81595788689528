import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { FaCalendar } from "react-icons/fa6";
import Swal from "sweetalert2";
import Loader from "../../common/loader/Loader";
import {
  db,
  eventRef,
  projectId
} from "../../firebase/FirebaseConfig";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import { fetchClientDetails, fetchEmployeeDetails } from "../../utils/function";
import AddEvent from "./AddEvent";

const Calender = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const { data, refetch } = useQuery({
    queryKey: ["event-list"],
    queryFn: async () => {
      return new Promise((resolve, reject) => {
        try {
          onSnapshot(query(eventRef), async (snapshot) => {
            const eventsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const promises = eventsData.map(async (event) => {
              const clientDoc = await getDoc(
                doc(db, constant.COLLECTIONS.USERS, event.clientId)
              );

              let employeeDoc = null;
              if (event.employeeId) {
                employeeDoc = await getDoc(
                  doc(db, constant.COLLECTIONS.USERS, event.employeeId)
                );
              }
              return {
                start: event?.startTime?.toDate(),
                end: event?.endTime?.toDate(),
                title: `Client: ${clientDoc.data()?.firstName} ${
                  clientDoc.data()?.lastName
                }, Employee: ${
                  event.employeeId
                    ? `${employeeDoc?.data()?.firstName} ${
                        employeeDoc?.data()?.lastName ?? ""
                      }`
                    : "N/A"
                }, Sync : ${event?.sync ? "True" : "False"} `,
                description: event?.notes,
                extendedProps: {
                  ...event,
                  client: clientDoc.data(),
                  employee: employeeDoc?.data() || null,
                },
                backgroundColor: event?.sync == true ? "Green" : "Red",
              };
            });

            const eventsWithDetails = await Promise.all(promises);
            resolve(eventsWithDetails);
          });
        } catch (err) {
          console.log("error", err);
          reject(err);
        }
      });
    },
  });

  const fetchSyncableEvents = async () => {
    try {
      const eventsQuery = query(
        eventRef,
        where("sync", "==", false),
        where("startTime", ">", new Date())
      );

      const querySnapshot = await getDocs(eventsQuery);
      const eventsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch client and employee details
      const eventsWithDetails = await Promise.all(
        eventsData.map(async (event) => {
          const clientDetails = await fetchClientDetails(event.clientId);
          const employeeDetails = await fetchEmployeeDetails(event.employeeId);

          return {
            ...event,
            client: clientDetails,
            employee: employeeDetails,
          };
        })
      );

      return eventsWithDetails;
    } catch (error) {
      console.error("Error fetching events: ", error);
      return [];
    }
  };

  const handleSync = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sync events.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          syncMutation.mutate();
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const syncMutation = useMutation({
    mutationFn: async () => {
      let data = await fetchSyncableEvents();

      let events = data?.map((item) => {
        const attendees = [];

        if (item?.employee?.email) {
          attendees.push({ email: item.employee.email });
        }

        if (item?.client?.email) {
          attendees.push({ email: item.client.email });
        }

        return {
          summary: `Appointment for ${item?.employee?.firstName} ${item?.employee?.lastName} with ${item?.client?.firstName} ${item?.client?.lastName}`,
          location: `${item?.client?.address} ${item?.client?.city} ${item?.client?.state} ${item?.client?.country} (${item?.client?.zipCode})`,
          description: `Caregiving service scheduled at ${moment(
            item?.startTime?.toDate()
          ).format("ll")} (${moment(item?.startTime?.toDate()).format(
            "LT"
          )}- ${moment(item?.endTime?.toDate()).format("LT")}), Note : ${
            item?.note
          }`,
          start: {
            dateTime: item?.startTime?.toDate(),
            timeZone: "America/Los_Angeles",
          },
          end: {
            dateTime: item?.endTime?.toDate(),
            timeZone: "America/Los_Angeles",
          },
          attendees: attendees,
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        };
      });

      const response = await fetch(
        `https://us-central1-${projectId}.cloudfunctions.net/createEvent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ events: events }),
        }
      );

      return { calendarResp: await response.json(), eventData: data };
    },

    onSuccess: async (resp) => {
      if (resp?.eventData?.length !== resp?.calendarResp?.data?.length) {
        console.error("Mismatch between eventData and calendarResp");
        return;
      }

      const batch = writeBatch(db);
      resp?.eventData?.forEach((item, index) => {
        const calendarEventId = resp?.calendarResp?.data?.[index]?.id;
        if (!calendarEventId) {
          console.error(`No calendarEventId for item at index ${index}`);
          return;
        }

        const docRef = doc(db, constant.COLLECTIONS.EVENTS, item?.id);
        batch.set(
          docRef,
          { sync: true, calendarEventId }, // Save the calendarEventId
          { merge: true }
        );
      });

      try {
        await batch.commit();
        toastAlert("success", resp?.calendarResp?.message);
        refetch(); // Refetch the data after the batch commit
      } catch (error) {
        console.error("Error updating documents: ", error);
      }
    },
  });

  return (
    <div className="main-content">
      <div className="commonSearchBar my-3">
        <h4>Employee Management</h4>
        <div className="endContent">
          <button className="greenBtn" onClick={handleSync}>
            <FaCalendar size={20} />
          </button>
          <button className="greenBtn" onClick={handleShow}>
            Add Event
          </button>
        </div>
      </div>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
        ]}
        events={data}
        eventClick={(el) => {
          const clickedEventDate = new Date(el.event.start);
          const today = new Date();

          if (clickedEventDate >= today.setHours(0, 0, 0, 0)) {
            setShow(el);
          }
        }}
        dayMaxEventRows={true}
        nowIndicator={true}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        dateClick={(el) => {
          const clickedDate = new Date(el.date);
          const today = new Date();

          if (clickedDate >= today.setHours(0, 0, 0, 0)) {
            setShow(el);
          }
        }}
        eventDidMount={(info) => {
          info.el.setAttribute("title", info.event.title);
        }}
      />

      <AddEvent show={show} setShow={setShow} refetch={refetch} />
      {syncMutation?.isPending && <Loader />}
    </div>
  );
};

export default Calender;
