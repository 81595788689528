import { doc, getDoc } from "firebase/firestore";
import { usersRef } from "../firebase/FirebaseConfig";
import { toastAlert } from "./SweetAlert";

export function setTimeFromString(date, timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

/**
 *
 * @param {*} checkinTime
 * @param {*} checkoutTime
 * @param {*} hourlyRate
 * @returns
 */

export const calculatePayoutAmount = (
  checkinTime,
  checkoutTime,
  hourlyRate
) => {
  // Parse checkin and checkout times (assuming "HH:mm" format)
  const [checkinHours, checkinMinutes] = checkinTime.split(":").map(Number);
  const [checkoutHours, checkoutMinutes] = checkoutTime.split(":").map(Number);

  // Create Date objects with the same date for both checkin and checkout times
  const today = new Date();
  const checkin = new Date(today.setHours(checkinHours, checkinMinutes, 0, 0));
  let checkout = new Date(today.setHours(checkoutHours, checkoutMinutes, 0, 0));

  // If checkout is earlier than checkin (means it's the next day)
  if (checkout < checkin) {
    checkout.setDate(checkout.getDate() + 1); // Add one day to checkout
  }

  // Calculate the difference in milliseconds
  const timeDifference = checkout - checkin;

  if (timeDifference <= 0) {
    toastAlert("error", "Invalid check-in or check-out times");
    return 0; // Return 0 if checkout is before checkin
  }

  // Convert milliseconds to hours
  const hoursWorked = timeDifference / (1000 * 60 * 60);

  // Calculate the payout by multiplying hours worked by hourly rate
  const payoutAmount = hoursWorked * hourlyRate;

  return parseFloat(payoutAmount.toFixed(2));
};



  // Helper function to fetch client details
  export const fetchClientDetails = async (clientId) => {
    if (!clientId) return null; // Handle missing clientId
    try {
      const clientDoc = await getDoc(doc(usersRef, clientId));
      return clientDoc.exists() ? clientDoc.data() : null;
    } catch (error) {
      console.error("Error fetching client details: ", error);
      return null;
    }
  };

  // Helper function to fetch employee details
  export const fetchEmployeeDetails = async (employeeId) => {
    if (!employeeId) return null; // Handle missing employeeId
    try {
      const employeeDoc = await getDoc(doc(usersRef, employeeId));
      return employeeDoc.exists() ? employeeDoc.data() : null;
    } catch (error) {
      console.error("Error fetching employee details: ", error);
      return null;
    }
  };