import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment/moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { db, usersRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";

const Client = () => {
  const [search, setSearch] = useState();
  const { data, refetch } = useQuery({
    queryKey: ["client-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.CLIENT),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const clients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (search) {
          return clients.filter(
            (i) =>
              i?.email?.includes(search) ||
              i?.firstName?.includes(search) ||
              i?.lastName?.includes(search)
          );
        }

        return clients;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteMutation.mutate(id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };
  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      return await deleteDoc(doc(db, constant.COLLECTIONS.USERS, id));
    },
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "User has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      refetch();
    },
  });

  return (
    <>
      <section className="main-content">
        <div className="commonSearchBar mt-3">
          <h4 className="heading20">Client Management</h4>
          <div className="endContent">
            <div className="clientSearch">
              <img
                src={images.Search}
                alt="searchImg"
                className="clientSearchIcon"
              />
              <input
                type="text"
                placeholder="Type and press enter to search."
                className="clientSearchInput"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) =>
                  e.key == "Enter" && search.trim() !== "" && refetch()
                }
                onKeyUp={(e) =>
                  e.target.value == "" && e.key == "Backspace" && refetch()
                }
              />
            </div>
            {/* <button className="greenBtn">Import</button> */}
            <Link className="greenBtn" to="../add-client">
              Add Client
            </Link>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="commonTable">
                  <thead className="tableHead">
                    <tr>
                      <th className="heading14 tableHeading">S. No</th>
                      <th className="heading14 tableHeading">Client Name</th>
                      <th className="heading14 tableHeading">Email</th>
                      <th className="heading14 tableHeading">Phone Number</th>
                      <th className="heading14 tableHeading">Address</th>
                      <th className="heading14 tableHeading">Created At</th>
                      <th className="heading14 tableHeading">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="heading14 tableHeading">
                              {" "}
                              {index + 1}
                            </td>
                            <td className="text-capitalize heading14 tableHeading">{`${item?.firstName} ${item?.lastName}`}</td>
                            <td className="heading14 tableHeading">
                              {item?.email}
                            </td>
                            <td className="heading14 tableHeading">
                              {item?.phoneNumber}
                            </td>
                            <td className="heading14 tableHeading">{`${item?.address} ${item?.city} ${item?.state} ${item?.country} (${item?.zipCode})`}</td>

                            <td className="heading14 tableHeading">
                              {moment(item?.createdAt?.toDate()).format("lll")}
                            </td>

                            <td>
                              <Link to={`../client/${item?.id}`}>
                                <img
                                  src={images.eye}
                                  className="me-2 editImg"
                                  alt="img"
                                  role="button"
                                />
                              </Link>
                              <Link to={`../add-client?id=${item?.id}`}>
                                <img
                                  src={images.edit}
                                  className="ms-2 editImg"
                                  alt="img"
                                  role="button"
                                />
                              </Link>

                              <img
                                src={images.deleteImg}
                                className="ms-2 "
                                alt="img"
                                role="button"
                                onClick={() => handleDelete(item?.id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={10}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {deleteMutation?.isPending && <Loader />}
    </>
  );
};

export default Client;
