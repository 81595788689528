import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../common/Navbar";
import Sidebar from "../common/Sidebar";
import useRole from "../hooks/useRole";
import { constant } from "./constants";
import useAuth from "../hooks/useAuth";

export const UserAuth = () => {
  const role = useRole();
  const isLoggedIn = useAuth();

  if (isLoggedIn) {
    if (role == constant.ROLE.ADMIN) {
      return <Navigate to="/admin/client" replace={true} />;
    } else {
      return (
        <>
          <Sidebar />
          <Navbar />
          <Outlet />
        </>
      );
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const AdminAuth = () => {
  const role = useRole();
  const isLoggedIn = useAuth();

  if (isLoggedIn) {
    if (role == constant.ROLE.EMPLOYEE) {
      return <Navigate to="/shifts" replace={true} />;
    } else {
      return (
        <>
          <Sidebar />
          <Navbar />
          <Outlet />
        </>
      );
    }
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const PublicAuth = () => {
  const role = useRole();
  const isLoggedIn = useAuth();

  if (isLoggedIn) {
    if (role == constant.ROLE.EMPLOYEE) {
      return <Navigate to="/shifts" replace={true} />;
    } else {
      return <Navigate to="/admin/client" replace={true} />;
    }
  } else {
    return <Outlet />;
  }
};
