import { useMutation, useQuery } from "@tanstack/react-query";
import {
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import { FaCamera } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { db, usersRef } from "../../firebase/FirebaseConfig";
import useDetails from "../../hooks/useDetails";
import { login } from "../../redux/features/authSlice";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
const Profile = () => {
  const storage = getStorage();
  const details = useDetails();
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      profileImg: "",
      newPicked: "",
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required().label("First Name").trim(),
      newPicked: yup.mixed().when(([newPicked], schema) => {
        if (newPicked) {
          return yup
            .mixed()
            .test("type", "Please choose png, jpg and jpeg", function (value) {
              return (
                value &&
                (value.type === "image/jpg" ||
                  value.type === "image/png" ||
                  value.type === "image/jpeg")
              );
            });
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const { refetch } = useQuery({
    queryKey: ["userProfile", details?.uid],
    queryFn: async () => {
      try {
        const q = query(usersRef, where("uid", "==", details?.uid));
        const querySnapshot = await getDocs(q);
        const currentUserDoc = querySnapshot.docs.find((doc) => !!doc);

        if (!currentUserDoc) {
          toastAlert("error", "User does not exist");
          return {};
        }
        const userData = currentUserDoc.data();
        setValues({
          ...values,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          profileImg: userData?.profileImg,
        });
        setId(currentUserDoc?.id);
        return userData;
      } catch (err) {
        console.log("err", err);
        return {};
      }
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        if (body?.newPicked) {
          const storageRef = ref(
            storage,
            `images/${uuidv4()}-${body.newPicked.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, body.newPicked);

          await new Promise((resolve, reject) => {
            uploadTask.on("state_changed", null, reject, async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              body.profileImg = downloadURL;
              resolve();
            });
          });
        }

        delete body?.newPicked;
        await setDoc(doc(db, constant.COLLECTIONS.USERS, id), body, {
          merge: true,
        });
        toastAlert("success", "Profile updated successfully");
      } catch (err) {
        console.error("Failed to update profile:", err);
        toastAlert("error", "Failed to update profile");
      }
    },
    onSuccess: async () => {
      const q = query(usersRef, where("uid", "==", details?.uid));
      const querySnapshot = await getDocs(q);
      const currentUserDoc = querySnapshot.docs.find((doc) => !!doc);
      const userData = currentUserDoc.data();
      if (userData?.createdAt instanceof Timestamp) {
        userData.createdAt = userData.createdAt.toDate().toISOString();
      }
      dispatch(login(userData));
      setIsEditing(false);
      refetch();
    },
  });

  return (
    <div className="main-content">
      <div className="userData">
        <div className="userLeft">
          <div className="userImg position-relative">
            <img
              src={
                values?.newPicked
                  ? URL.createObjectURL(values?.newPicked)
                  : values?.profileImg
                  ? values?.profileImg
                  : images.profile
              }
              alt="profile"
              width={250}
              className="profileUploadImg"
            />
            {isEditing && (
              <label htmlFor="profileImage" className="cameraUpload">
                <FaCamera size={20} />
              </label>
            )}

            <input
              type="file"
              id="profileImage"
              className="d-none"
              accept="image/*"
              onChange={(e) => setFieldValue("newPicked", e.target.files[0])}
            />
            <small className="text-danger ms-1">
              {touched.newPicked && errors.newPicked}
            </small>
          </div>
          <div className="userInfo p-3">
            <div className="userName mb-3">
              <h6 className="heading16 mb-0">First Name</h6>
              {isEditing ? (
                <input
                  type="text"
                  name="firstName"
                  value={values?.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
              ) : (
                <p className="mb-0">{`${values?.firstName}`}</p>
              )}

              <small className="text-danger requiredTxt">
                {touched?.firstName && errors?.firstName}
              </small>
            </div>
            <div className="userName mb-3">
              <h6 className="heading16 mb-0">Last Name</h6>
              {isEditing ? (
                <input
                  type="text"
                  name="lastName"
                  value={values?.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
              ) : (
                <p className="mb-0">{values?.lastName}</p>
              )}
            </div>
            <div className="userName mb-3">
              <h6 className="heading16 mb-0">Email</h6>
              {isEditing ? (
                <input
                  type="email"
                  name="email"
                  value={values?.email}
                  readOnly
                  className="form-control"
                />
              ) : (
                <p className="mb-0">{values?.email}</p>
              )}
            </div>
            {isEditing && (
              <button
                type="button"
                onClick={handleSubmit}
                className="greenBtn mt-3"
              >
                Save
              </button>
            )}
          </div>
        </div>
        <div className="userRight">
          {isEditing ? (
            <button
              onClick={() => {
                setIsEditing(false);
                refetch();
              }}
              className="cancleBtn"
            >
              Cancel
            </button>
          ) : (
            <button
              onClick={() => {
                setIsEditing(true);
              }}
              className="greenBtn"
            >
              Edit Profile
            </button>
          )}
        </div>
      </div>
      {mutation?.isPending && <Loader />}
    </div>
  );
};

export default Profile;
