import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import * as yup from "yup";
import * as images from "../../assets/image";
import Loader from "../../common/loader/Loader";
import { getErrorMessage } from "../../firebase/errors";
import { db, projectId, usersRef } from "../../firebase/FirebaseConfig";
import { constant } from "../../utils/constants";
import { fetchClientDetails, fetchEmployeeDetails } from "../../utils/function";
import moment from "moment";
import { toastAlert } from "../../utils/SweetAlert";

const AddEvent = ({ show, setShow, refetch }) => {
  const dateRef = useRef();

  const handleClose = () => {
    setShow(false);
    resetForm();
    refetch();
  };

  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      id: "",
      employeeId: "",
      shiftDate: new Date(),
      startTime: "",
      endTime: "",
      clientId: "",
      note: "",
      sync: false,
    },
    validationSchema: yup.object().shape({
      shiftDate: yup.date().required().label("Shift date"),
      startTime: yup
        .string()
        .required()
        .label("Start time")
        .test(
          "is-greater-than-current",
          "Start time should be later than the current time",
          function (value) {
            let startTime = new Date(value);
            return startTime >= new Date();
          }
        ),
      endTime: yup
        .string()
        .required()
        .label("End time")
        .test(
          "is-greater-than-startTime",
          "End time should be later than start time",
          function (value) {
            const { startTime } = this.parent;
            const start = new Date(startTime);
            const end = new Date(value);
            return end > start;
          }
        ),
      clientId: yup.string().required().label("Client"),
    }),

    onSubmit: async (values) => {
      let body = {
        employeeId: values.employeeId,
        clientId: values.clientId,
        note: values?.note,
        createdAt: Timestamp.now(),
        sync: values?.sync,
      };
      mutation.mutate(body);
    },
  });

  const { data: employeeList } = useQuery({
    queryKey: ["employee-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.EMPLOYEE),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const employee = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return employee;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const { data: clientList } = useQuery({
    queryKey: ["client-list"],
    queryFn: async () => {
      try {
        let q = query(
          usersRef,
          where("role", "==", constant.ROLE.CLIENT),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);

        const clients = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return clients;
      } catch (err) {
        console.log("error", err);
        return [];
      }
    },
  });

  const mutation = useMutation({
    mutationFn: async (body) => {
      try {
        const startTime = new Date(values?.startTime);
        const endTime = new Date(values?.endTime);

        body.startTime = startTime;
        body.endTime = endTime;

        if (values?.employeeId) {
          const { employeeId } = values;

          const eventQuery = query(
            collection(db, constant.COLLECTIONS.EVENTS),
            where("employeeId", "==", employeeId),
            where("startTime", "<=", endTime),
            where("endTime", ">=", startTime),
            where("startTime", ">=", new Date())
          );

          const leaveQuery = query(
            collection(db, constant.COLLECTIONS.LEAVES),
            where("employeeId", "==", employeeId),
            where("startDateTime", "<=", endTime),
            where("endDateTime", ">=", startTime),
            where("startDateTime", ">=", new Date())
          );

          let conflictFound = false;
          let pendingLeaveFound = false;

          try {
            let eventSnapshot;
            if (values?.id) {
              const eventQueryWithExclusion = query(
                eventQuery,
                where(documentId(), "!=", values.id)
              );
              eventSnapshot = await getDocs(eventQueryWithExclusion);
            } else {
              eventSnapshot = await getDocs(eventQuery);
            }

            if (!eventSnapshot.empty) {
              conflictFound = true;
            }
          } catch (error) {
            console.error("Error fetching events:", error);
            throw new Error("Error checking event conflicts");
          }

          // Query for leave conflicts
          try {
            const leaveSnapshot = await getDocs(leaveQuery);
            leaveSnapshot.forEach((doc) => {
              const leaveData = doc.data();
              if (leaveData.status === constant.LEAVE_STATUS.APPROVED) {
                conflictFound = true;
              }
              if (leaveData.status === constant.LEAVE_STATUS.PENDING) {
                pendingLeaveFound = true;
              }
            });
          } catch (error) {
            console.error("Error fetching leaves:", error);
            throw new Error("Error checking leave conflicts");
          }

          // Handle conflicts
          if (conflictFound) {
            Swal.fire({
              title: "Conflict Found!",
              text: "Employee has another schedule or is on leave at this time.",
              icon: "warning",
              confirmButtonColor: "#0d1227",
            });
            handleClose();
            return;
          }

          if (pendingLeaveFound) {
            Swal.fire({
              title: "Leave Found!",
              text: "Employee submitted a leave request. Please approve or reject it before scheduling the event.",
              icon: "warning",
              confirmButtonColor: "#0d1227",
            });

            handleClose();
            return;
          }
        }

        // Saving event in Firestore
        try {
          if (values?.id) {
            try {
              const googleEventId = show?.event?.extendedProps?.calendarEventId;
              let client = await fetchClientDetails(values?.clientId);
              let employee = await fetchEmployeeDetails(values?.employeeId);

              const attendees = [];

              if (employee?.email) {
                attendees.push({ email: employee.email });
              }

              if (client?.email) {
                attendees.push({ email: client.email });
              }

              if (googleEventId) {
                const [googleResponse, firestoreResponse] = await Promise.all([
                  fetch(
                    `https://us-central1-${projectId}.cloudfunctions.net/updateEvent`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        eventId: googleEventId,
                        event: {
                          summary: values?.employeeId
                            ? `Appointment for ${employee?.firstName} ${employee?.lastName} with ${client?.firstName} ${client?.lastName}`
                            : `Appointment with ${client?.firstName} ${client?.lastName}`,
                          location: `${client?.address} ${client?.city} ${client?.state} ${client?.country} (${client?.zipCode})`,
                          description: `Caregiving service scheduled at ${moment(
                            values?.startTime
                          ).format("ll")} (${moment(values?.startTime).format(
                            "LT"
                          )}- ${moment(values?.endTime).format(
                            "LT"
                          )}), Note : ${values?.note}`,
                          start: {
                            dateTime: values?.startTime,
                            timeZone: "America/Los_Angeles",
                          },
                          end: {
                            dateTime: values?.endTime,
                            timeZone: "America/Los_Angeles",
                          },
                          attendees: attendees,
                          reminders: {
                            useDefault: false,
                            overrides: [
                              { method: "email", minutes: 24 * 60 },
                              { method: "popup", minutes: 10 },
                            ],
                          },
                        },
                      }),
                    }
                  ).then((res) => res.json()), // Handle the Google event response
                  setDoc(
                    doc(db, constant.COLLECTIONS.EVENTS, values.id),
                    body,
                    {
                      merge: true,
                    }
                  ), // Fire store event update
                ]);
                toastAlert("success", "Event updated successfully");
                return { googleResponse, firestoreResponse };
              }
              toastAlert("success", "Event updated successfully");
              return await setDoc(
                doc(db, constant.COLLECTIONS.EVENTS, values.id),
                body,
                {
                  merge: true,
                }
              );
            } catch (err) {
              console.log("err", err);
            }
          } else {
            const eventRef = collection(db, constant.COLLECTIONS.EVENTS);
            await addDoc(eventRef, body);
          }
          return true;
        } catch (error) {
          console.error("Error saving event:", error);
          throw new Error(getErrorMessage(error));
        }
      } catch (error) {
        console.error("Error in mutation:", error);
        throw new Error("An error occurred during the mutation");
      }
    },
    onSuccess: () => {
      handleClose();
    },
  });

  useEffect(() => {
    let data = show?.event?.extendedProps;

    if (data) {
      setValues((prevValues) => ({
        ...prevValues,
        id: data?.id,
        employeeId: data?.employeeId,
        shiftDate: new Date(data?.startTime?.toDate()),
        startTime: new Date(data?.startTime?.toDate()),
        endTime: new Date(data?.endTime?.toDate()),
        clientId: data?.clientId,
        note: data?.note,
        sync: data?.sync,
      }));
    }

    if (show?.date) {
      setFieldValue("shiftDate", new Date(show?.date));
    }
  }, [show?.event?.extendedProps?.id || show?.date]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d1227",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          deleteMutation.mutate(values?.id);
        } catch (err) {
          console.log("err", err);
        }
      }
    });
  };

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      const googleEventId = show?.event?.extendedProps?.calendarEventId;

      try {
        // If there's a calendarEventId, delete both the Google Calendar event and Firestore event
        if (googleEventId) {
          const [googleResponse, firestoreResponse] = await Promise.all([
            fetch(
              `https://us-central1-${projectId}.cloudfunctions.net/deleteEvent`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  eventId: googleEventId,
                }),
              }
            ).then((res) => res.json()), // Handle the Google event response
            deleteDoc(doc(db, constant.COLLECTIONS.EVENTS, id)), // Firestore delete
          ]);

          return { googleResponse, firestoreResponse };
        }

        // If no calendarEventId, only delete from Firestore
        return await deleteDoc(doc(db, constant.COLLECTIONS.EVENTS, id));
      } catch (error) {
        throw new Error("Failed to delete the event from one or more sources");
      }
    },
    onSuccess: () => {
      Swal.fire({
        title: "Deleted!",
        text: "Event has been deleted.",
        icon: "success",
        confirmButtonColor: "#0d1227",
      });
      handleClose();
    },
  });

  return (
    <Modal show={!!show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {!!values?.id ? "Update Schedule" : "Add Schedule"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Client <span className="text-danger">*</span>
              </label>
              <select
                name="clientId"
                className="inputBox"
                value={values?.clientId}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {clientList &&
                  clientList?.map((i, index) => (
                    <option
                      value={i.id}
                      key={index}
                    >{`${i?.firstName} ${i?.lastName}`}</option>
                  ))}
              </select>

              <small className="text-danger requiredTxt">
                {touched.clientId && errors.clientId}
              </small>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">Employee</label>
              <select
                className="inputBox"
                value={values?.employeeId}
                name="employeeId"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {employeeList &&
                  employeeList?.map((i, index) => (
                    <option
                      key={index}
                      value={i.id}
                    >{`${i?.firstName} ${i?.lastName}`}</option>
                  ))}
              </select>

              <small className="text-danger requiredTxt">
                {touched.employeeId && errors.employeeId}
              </small>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="form-group position-relative">
              <label className="labelTxt">
                Shift Date <span className="text-danger">*</span>
              </label>
              <img
                src={images.calendar}
                className="calanderIcon"
                alt="calendarImg"
                role="button"
                onClick={() => dateRef?.current?.setFocus()}
              />

              <DatePicker
                onChange={(date) => setFieldValue("shiftDate", date)}
                className="inputBox"
                selected={values?.shiftDate}
                minDate={new Date()}
                ref={dateRef}
                onBlur={() => setFieldTouched("shiftDate", true)}
              />

              <small className="text-danger requiredTxt">
                {touched.shiftDate && errors.shiftDate}
              </small>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Scheduled Start Time <span className="text-danger">*</span>
              </label>
              <DatePicker
                selected={values?.startTime}
                onChange={(date) => {
                  if (date && values?.shiftDate) {
                    const combinedDate = new Date(values.shiftDate);
                    combinedDate.setHours(date.getHours());
                    combinedDate.setMinutes(date.getMinutes());
                    setFieldValue("startTime", combinedDate);
                  }
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="inputBox"
                onBlur={() => setFieldTouched("startTime", true)}
              />

              <small className="text-danger requiredTxt">
                {touched.startTime && errors.startTime}
              </small>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="form-group">
              <label className="labelTxt">
                Scheduled End Time <span className="text-danger">*</span>
              </label>

              <DatePicker
                selected={values?.endTime}
                onChange={(date) => {
                  if (date && values?.shiftDate) {
                    const combinedDate = new Date(values.shiftDate);
                    combinedDate.setHours(date.getHours());
                    combinedDate.setMinutes(date.getMinutes());
                    setFieldValue("endTime", combinedDate);
                  }
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="inputBox"
                onBlur={() => setFieldTouched("endTime", true)}
              />

              <small className="text-danger requiredTxt">
                {touched.endTime && errors.endTime}
              </small>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="form-group">
              <label className="labelTxt">Note</label>
              <textarea
                rows={3}
                name="note"
                className="inputBox"
                value={values?.note}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <small className="text-danger requiredTxt">
                {touched.note && errors.note}
              </small>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <button onClick={handleClose} type="button" className="cancleBtn">
            Close
          </button>
          {!!values?.id && (
            <button
              onClick={handleDelete}
              type="button"
              className="cancleBtn delete"
            >
              Delete
            </button>
          )}
          <button className="greenBtn" type="button" onClick={handleSubmit}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal.Body>
      {(mutation?.isPending || deleteMutation?.isPending) && <Loader />}
    </Modal>
  );
};

export default AddEvent;
