export const constant = Object.freeze({
  /*******************Roles******************/
  ROLE: {
    ADMIN: 1,
    EMPLOYEE: 2,
    CLIENT: 3,
  },

  /********************Admin Signup***********/
  ADMIN_NAME: "Admin",
  ADMIN_EMAIL: "admin@itech.com",
  ADMIN_PASSWORD: "Admin@123",

  /*****************Pagination*****************/
  PAGE_NO_ONE: 1,
  PER_PAGE_TEN: 10,

  /**********************Collections**********/
  COLLECTIONS: {
    USERS: "users",
    EVENTS: "events",
    PAYOUT: "payout",
    LEAVES: "leaves",
  },

  LEAVE_STATUS: {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
  },
  DISCOVERY_DOCS: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  SCOPES: "https://www.googleapis.com/auth/calendar.events",
});
