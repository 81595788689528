import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavigateBack from "../../common/NavigateBack";
import { db } from "../../firebase/FirebaseConfig";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";

const Details = () => {
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["client-details", id],
    queryFn: async () => {
      try {
        const userDoc = await getDoc(doc(db,  constant.COLLECTIONS.USERS, id));

        if (userDoc.exists()) {
          const data = userDoc.data();
          return data;
        } else {
          toastAlert("error", "User does not exist");
        }
      } catch (error) {
        console.log("error", error);
        toastAlert("error", "An error occurred while fetching user data");
      }
    },
  });

  return (
    <>
      <section className="main-content">
        <NavigateBack>Client Details</NavigateBack>
        <Card border="light" bg="light" className="p-4">
          <div className="clientDetails">
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Client Name</h6>
              <span className="heading16 ps-2 text-capitalize">{`${data?.firstName} ${data?.lastName}`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Email</h6>
              <span className="heading16 ps-2">{data?.email}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Phone Number</h6>
              <span className="heading16 ps-2">{data?.phoneNumber}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Address</h6>
              <span className="heading16 ps-2">{`${data?.address} ${data?.city} ${data?.state} ${data?.country} (${data?.zipCode})`}</span>
            </div>
            <div className="clientDetailsContent mb-3">
              <h6 className="heading18 mb-0">Created At</h6>
              <span className="heading16 ps-2">
                {moment(data?.createdAt?.toDate()).format("lll")}
              </span>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Details;
